/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */
/* jshint esversion: 8 */
(function ($) {
    // Use this variable to set up the common and page specific functions. If you
    // rename this variable, you will also need to rename the namespace below.
    var Sage = {
        // All pages
        common: {
            init: function () { },
            finalize: function () { }
        },
        // Home page
        home: {
            init: function () {
                // JavaScript to be fired on the home page
            },
            finalize: function () {
                // JavaScript to be fired on the home page, after the init JS
            }
        },
        // About us page, note the change from about-us to about_us.
        about_us: {
            init: function () {
                // JavaScript to be fired on the about us page
            }
        }
    };
    // console.log('test');

    // The routing fires all common scripts, followed by the page specific scripts.
    // Add additional events for more control over timing e.g. a finalize event
    var UTIL = {
        fire: function (func, funcname, args) {
            var fire;
            var namespace = Sage;
            funcname = funcname === undefined ? "init" : funcname;
            fire = func !== "";
            fire = fire && namespace[func];
            fire = fire && typeof namespace[func][funcname] === "function";

            if (fire) {
                namespace[func][funcname](args);
            }
        },
        loadEvents: function () {
            // Fire common init JS
            UTIL.fire("common");

            // Fire page-specific init JS, and then finalize JS
            $.each(document.body.className.replace(/-/g, "_").split(/\s+/), function (
                i,
                classnm
            ) {
                UTIL.fire(classnm);
                UTIL.fire(classnm, "finalize");
            });

            // Fire common finalize JS
            UTIL.fire("common", "finalize");
        }
    };

    // Load Events
    $(document).ready(UTIL.loadEvents);

    //start main.js


    $(window).on('load', function () {

        // $('body').removeClass('loading');

        function setVerticalFlexedWidth(items) {
            // for elements that use flex direction:column and flex-wrap:wrap
            var nums = [];
            items.each(function () {
                if (!nums.includes($(this).innerWidth())) {
                    nums.push($(this).innerWidth());
                }
            });
            var listWidth = 0;
            nums.forEach(function (num) {
                listWidth += num;
            });
            items.parent().width(listWidth);
        }

        //handle main nav searh bar

        $(".main-nav__item_search").on('click', function () {
            $(".main-nav__search").addClass("active");
            $(this).parents().find(".main-nav").addClass("main-nav_search");
            $(".main-nav__search input").focus();

        });

        $(".main-nav__open-item").on("click", function () {
            $(".main-nav__open .main-nav__switch").trigger("click");
            $(".main-nav__item_search").trigger("click");
        });

        $(".main-nav__search i.icon-cross").on('click', function () {
            $(".main-nav__search").removeClass("active");
            $(this).parents().find(".main-nav").removeClass("main-nav_search");

        });
        //end of main nav searh bar

        // footer controls

        var footerControls = $(".footer__controls > div");
        var mobileBtn = $(".footer__close");

        mobileBtn.on("click", function () {
            $(".footer__controls .active").trigger("click");
        });

        footerControls.on("click", function () {
            var isActive = false;
            var index = $(this).index();
            var lists = $(this).parents().eq(1).find(".footer__data");
            var isOpen = false;

            footerControls.each(function () {

                if ($(this).hasClass("active")) {
                    isOpen = true;
                }

            });

            if ($(this).hasClass("active")) {
                isActive = true;
            }

            footerControls.removeClass("active");
            mobileBtn.removeClass("active");
            lists.slideUp(300);

            if (isOpen) {
                var self = $(this);
                setTimeout(function () {
                    openData(isActive, self, lists, index, mobileBtn);
                }, 350);
            } else {
                openData(isActive, $(this), lists, index, mobileBtn);
            }

        });

        function toData() {
            $('html, body').animate({
                scrollTop: $(".footer__controls").offset().top - 75
            }, 300);
        }

        function openData(isActive, btn, lists, index, mobile) {
            if (!isActive) {
                btn.addClass("active");
                mobile.addClass("active");
                lists.eq(index).slideDown(300);
                toData();
            }
        }

        // end of footer controls

        //remove empty list items form the footer
        if ($(window).innerWidth() < 1281) {
            var footerItems = $(".footer__data_world .footer__list:nth-of-type(2) ul li");
            var footrItemsText = '';
            footerItems.each(function () {

                if ($(this)[0].textContent) {
                    footrItemsText += "<li>" + $(this)[0].textContent + "</li>";
                }

            });

            $(".footer__data_world .footer__list:nth-of-type(2) ul").html(footrItemsText);
        }


        // add sticky nav

        $(document).on("scroll", function () {
            var scroll = $(window).scrollTop();
            if (scroll > 500) {
                $(".main-nav").addClass("ready");
            } else {
                $(".main-nav").removeClass("ready");
            }

            if (scroll > 700) {
                $(".main-nav").addClass("main-nav_sticky");
                $(".header-c").addClass("padding");

            } else {
                $(".main-nav").removeClass("main-nav_sticky");
                $(".header-c").removeClass("padding");
            }

            if (scroll > 1000) {
                $(".main-nav").addClass("active");
            } else {
                $(".main-nav").removeClass("active");
            }
        });

        // end of sticky nav


        // sliders

        $(".press__list").not('.slick-initialized').slick({
            autoplay: false,
            infinite: true,
            slidesToScroll: 1,
            slidesToShow: 6,
            arrows: false,
            dots: false,
            centerMode: true,
            variableWidth: true,
            pauseOnFocus: false,
            focusOnSelect: true,
            centerPadding: 0,
            asNavFor: '.press__article-grps'
        });

        $(".press__article-grps").not('.slick-initialized').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: '.press__list',
            adaptiveHeight: true,
        });

        // $('.press__article-grps').on('afterChange', function (event, slick, currentSlide, nextSlide) {

        //     var sliderAdaptiveHeightMobile = function () {
        //         $(".press__article-grps").find('.slick-slide').height('0');
        //         $(".press__article-grps").find('.slick-slide.slick-active').height('auto');
        //         $(".press__article-grps").find('.slick-list').height('auto');
        //         $(".press__article-grps").slick('setOption', null, null, true);
        //         $(".press__article-grps")[0].slick.animateHeight();

        //     }

        //     sliderAdaptiveHeightMobile();
        // });


        $('.clients__list').not('.slick-initialized').each(function () {
            var self = $(this);
            if (self.closest('section').hasClass('autoplay-slider')) {
                self.slick({
                    autoplay: true,
                    infinite: true,
                    slidesToScroll: 1,
                    autoplaySpeed: 0,
                    speed: 2500,
                    cssEase: 'linear',
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    variableWidth: true,
                    initialSlide: 8,
                    pauseOnFocus: true,
                });
            } else {
                self.slick({
                    autoplay: false,
                    infinite: true,
                    slidesToScroll: 1,
                    //autoplaySpeed: 1500,
                    arrows: false,
                    dots: true,
                    centerMode: true,
                    variableWidth: true,
                    initialSlide: 8,
                    pauseOnFocus: false,
                });
            }
        });




        // the water waterweel into slick
        // var width = (window.innerWidth < screen.width) ? window.innerWidth : screen.width;
        var width = window.innerWidth;

        if (width > 1600) {
            if ($(".cards.slick-initialized")[0]) {
                $('.cards').slick('unslick');
            }
        } else {
            headerCards();
        }

        if (width < 1100) {

            testimonialsSlider();
            logosSlider();
            globalHeaderSlider();

        }

        if (width <= 650) {
            importanceSmall();
        }

        if (width > 1100) {
            setTimeout(function () {
                setVerticalFlexedWidth($(".main-nav__calc > li"));
            }, 1000);
        }

        if (width > 1008) {
            $(".header-about .header-c__list").removeClass("header-c__list_mobile");
        }

        if (width > 700) {
            if ($(".achievements__list.slick-initialized")[0]) {
                $('.achievements__list').slick('unslick');
            }
        } else {
            achievementsSlider();
        }


        $(window).on('resize', function () {
            var width = (window.innerWidth < screen.width) ? window.innerWidth : screen.width;
            if (width <= 650) {
                importanceSmall();
            } else {
                if ($(".slick-initialized.importance__row_desk")[0]) {
                    $('.importance__row_desk').slick('unslick');
                }
            }

            if (width > 1100) {
                setTimeout(function () {
                    setVerticalFlexedWidth($(".main-nav__calc > li"));
                }, 1000);
            }

            if (width < 1100) {
                testimonialsSlider();
                logosSlider();
                globalHeaderSlider();

            } else {
                if ($(".slick-initialized.slider-content")[0]) {
                    $('.slider-content').slick('unslick');
                }
                if ($(".slick-initialized.logos__list")[0]) {
                    $('.logos__list').slick('unslick');
                }
                if ($(".slick-initialized.header-global__list")[0]) {
                    $('.header-global__list').slick('unslick');
                }
            }

            if (width > 1600) {
                if ($(".cards.slick-initialized")[0]) {
                    $('.cards').slick('unslick');
                }
            } else {
                headerCards();
            }

            if (width > 1008) {
                $(".header-about .header-c__list").removeClass("header-c__list_mobile");
                if ($(".header-about .header-c__list.slick-initialized")[0]) {
                    $('.header-about .header-c__list').slick('unslick');
                }
            } else {
                $(".header-about .header-c__list").addClass("header-c__list_mobile");
                headerSlider();
            }

            if (width > 700) {
                if ($(".achievements__list.slick-initialized")[0]) {
                    $('.achievements__list').slick('unslick');
                }
            } else {
                achievementsSlider();
            }

        });

        function headerCards() {
            $('.cards').not('.slick-initialized').slick({
                autoplay: true,
                slidesToScroll: 1,
                arrows: false,
                dots: true,
                centerMode: true,
                slidesToShow: 5,
                pauseOnFocus: false,
                responsive: [{
                    breakpoint: 1450,
                    settings: {
                        slidesToShow: 4,
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        variableWidth: true,
                    }
                }
                ]
            });
        }

        function globalHeaderSlider() {
            $(".header-global__list").not('.slick-initialized').slick({
                autoplay: true,
                infinite: true,
                arrows: false,
                dots: true,
                pauseOnFocus: false,
                slidesToScroll: 1,
                slidesToShow: 3,
                centerMode: true,
                variableWidth: true,
            });
        }


        function achievementsSlider() {
            $(".achievements__list").not('.slick-initialized').slick({
                autoplay: false,
                infinite: true,
                arrows: false,
                dots: true,
                pauseOnFocus: false,
                rows: 3,
                // centerMode: true,
                // variableWidth: true,
            });
        }

        function logosSlider() {
            $('.logos__list').not('.slick-initialized').slick({
                autoplay: true,
                infinite: true,
                slidesToScroll: 1,
                arrows: false,
                dots: false,
                variableWidth: true,
                centerMode: true,
                pauseOnFocus: false,
            });
        }

        function testimonialsSlider() {
            $('.slider-content').not('.slick-initialized').slick({
                autoplay: true,
                infinite: true,
                slidesToScroll: 1,
                arrows: true,
                dots: true,
                centerMode: true,
                variableWidth: true,
                pauseOnFocus: false,
                adaptiveHeight: true,
                prevArrow: '<a class="slider-left testimonials__arrow testimonials__arrow_prev" href="javascript:void(0);"><i class="icon-chevron-left"></i></a>',
                nextArrow: '<a class="slider-right testimonials__arrow testimonials__arrow_next" href="javascript:void(0);"><i class="icon-chevron-right"></i></a>',
            });
        }

        importanceSlider();

        function importanceSlider() {
            $(".importance__row_mobile").not('.slick-initialized').slick({
                autoplay: true,
                infinite: true,
                arrows: true,
                dots: true,
                pauseOnFocus: false,
                centerMode: true,
                variableWidth: true,

                prevArrow: '<a class="slider-left testimonials__arrow testimonials__arrow_prev" href="javascript:void(0);"><i class="icon-chevron-left"></i></a>',
                nextArrow: '<a class="slider-right testimonials__arrow testimonials__arrow_next" href="javascript:void(0);"><i class="icon-chevron-right"></i></a>',
            });
        }

        function importanceSmall() {
            $(".importance__row_desk").not('.slick-initialized').slick({
                autoplay: true,
                infinite: true,
                arrows: false,
                dots: true,
                pauseOnFocus: false,
                centerMode: true,
                variableWidth: true,
            });
        }

        headerSlider();

        function headerSlider() {
            $(".header-c__list_mobile").not('.slick-initialized').slick({
                autoplay: true,
                infinite: true,
                arrows: false,
                dots: true,
                pauseOnFocus: false,
                centerMode: true,
                variableWidth: true,
            });
        }


        var randomCards = $('.cards')[0];

        animateCards(randomCards);

        //randomly rotate the cards
        cardInterval = setInterval(function () {
            animateCards(randomCards);
        }, 2000);

        function animateCards(slick) {
            if (!slick) return;
            var cards = slick.querySelectorAll('.cards__card');
            cards.forEach(function (card) {

                var skip = Math.random() < 0.5 ? 0 : 1;

                if (skip) {
                    return;
                }

                var plusOrMinus = Math.random() < 0.5 ? -1 : 1;

                var x = Math.floor(Math.random() * 3) + 1;
                var y = Math.floor(Math.random() * 3) + 1;
                var top = Math.floor(Math.random() * 10) + 1;

                x *= plusOrMinus;
                y *= plusOrMinus;
                top *= plusOrMinus;

                card.style.transform = "perspective(10em) rotateX(" + x + "deg) rotateY(" + y + "deg) translateY(" + top + "px)";
            });
        }


        var testStep = 1;
        $(".needs__item").on('click', function () {
            if (!!$(this).find("a")[0]) {
                return;
            }
            addStep();
        });

        $(".needs__check_single input").on("change", function () {
            var inputVal = $(this).parents().eq(1).find("input").eq(0).val();
            var self = $(this);
            if (this.checked && inputVal)
                addStep();
            else {
                $(this).parents().eq(1).find(".needs__single-input").addClass("err");
                setTimeout(function () {
                    self.prop("checked", false);
                }, 500);
            }
        });

        function addStep() {
            var testBlock = $(".needs");
            testBlock.removeClass("needs_" + testStep);
            testStep++;
            testBlock.addClass("needs_" + testStep);
        }

        function lockInputs() {
            if (!$(".lock-inputs")[0]) {
                return;
            }
            $(".needs__side_step5 input").on("focus", function (e) {
                if (!$(".lock-inputs")[0].checked) {
                    $(this).attr("autocomplete", "off");
                    $(this).trigger("blur");
                    // e.preventDefault();
                    $(".lock-inputs").parent().addClass("err");
                } else {
                    $(this).attr("autocomplete", "on");
                }
            });

            $(".lock-inputs").on("change", function () {
                $(this).parent().removeClass("err");
            });
        }

        lockInputs();

        //end of test

        // validate test form
        function handleTestForm() {
            var email = $("#email");
            var phone = $("#phone");
            var name = $("#name");
            var url = $("#url");
            var text = $("#message");
            var check = $("#check");
            var isChecked = false;

            check.on("change", function () {
                isChecked = this.checked;
            });

            var hasErrs = false;

            function validateForm(e) {
                var mailValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                var isValidMail = mailValidation.test(String(email.val()).toLowerCase());

                var notValid = !isValidMail || !phone.val() || !url.val() || !name.val() || (text[0] && !text.val()) || (check[0] && !isChecked);

                if (notValid) {
                    e.preventDefault();
                    // hasErrs = true;

                    if (!isValidMail) {
                        email.parent().addClass("err");
                    }

                    if (!phone.val()) {
                        phone.parent().addClass("err");
                    }

                    if (!url.val()) {
                        url.parent().addClass("err");
                    }

                    if (!name.val()) {
                        name.parent().addClass("err");
                    }

                    if (!text.val()) {
                        text.parent().addClass("err");
                    }

                    if (!isChecked) {
                        check.parent().addClass("err");
                    }

                } else {
                    // hasErrs = false;
                }
            }

            $(".needs__submit, .info__form .cta").on('click', validateForm);

            $(".needs__single-input input, .needs__form input, .info__form input, .info__form textarea").on('focus', function () {
                $(this).parent().removeClass("err");
            });

            $(".info__form input, .info__form textarea").on("blur", function name() {
                if (hasErrs && !$(this).val()) {
                    $(this).parent().addClass("err");
                }
            });
        }

        handleTestForm();

        // end of test form validation
        setTimeout(function () {
            $(".services__more").parent().find('.services__list').addClass("closed");

            // var initialHeight = $(".services__list").height();
            // $(".services__list").height(initialHeight);

            var letter = $(".why-us__letter").find("div").eq(0);
            // var letterHeight = letter.height();
            // letter.addClass("closed");  // removed read more
            // letter.height(letterHeight);

        }, 1);

        function calcHeight(list, num /* number of items to show */) {
            var btn = list.parent().find(".services__more_step");

            // If already has all loaded and click is to close - scroll to beginning of section
            if (btn.hasClass('active')) {

                $('html, body').animate({
                    scrollTop: $(".services_home").offset().top - 75
                }, 500);

            }
            let itemToShow;

            if (num + 1 < list.children().length) {
                itemToShow = list.children().eq(num); // last item to show
                let mod = num + 4; // the next step
                list.addClass("closed");
                btn.removeClass("active");
                btn.html(btn.html().replace("Close", "View More"));
            } else {
                itemToShow = list.children().last();
                var mod = 3;
                list.removeClass("closed");
                btn.addClass("active");
                btn.html(btn.html().replace("View More", "Close"));
            }

            // the needed height to show the element for the step
            var itemEnd = (itemToShow.offset().top + itemToShow.height()) + 80 - list.offset().top;

            btn.off("click");
            btn.on("click", function () {
                calcHeight(list, mod);
            });

            list.height(itemEnd);
        }

        $(".services__list_step").each(function () {
            calcHeight($(this), 3);
        });


        function bindViewMoreHalfSectionClick(container, button) {

            button.on('click', function () {

                if (container.hasClass('opened')) {
                    $('html, body').animate({
                        scrollTop: container.offset().top - 75
                    }, 500);
                }

                container.toggleClass('opened').css({
                    transition: '0.9s ease'
                });

                if (container.hasClass('opened')) {
                    container.find('.section-half__img').parent().addClass('sticked');
                    button.html('View Less <i class="icon-chevron-up"></i>');
                } else {
                    container.find('.section-half__img').parent().removeClass('sticked');
                    button.html('View More <i class="icon-chevron-down"></i>');
                }

            });

        }

        function bindViewMoreFullSectionClick(container, button) {

            button.on('click', function () {

                if (container.hasClass('opened')) {
                    $('html, body').animate({
                        scrollTop: container.offset().top - 75
                    }, 500);
                }

                container.toggleClass('opened').css({
                    transition: '0.9s ease'
                });

                if (container.hasClass('opened')) {
                    button.html('View Less <i class="icon-chevron-up"></i>');
                } else {
                    button.html('View More <i class="icon-chevron-down"></i>');
                }

            });

        }

        function bindViewMoreSections() {

            var $sections = $('section:not(.logos):not(.geo-data-images)');

            var count = $sections.length - 1;
            var $order = 1;

            $sections.each(function (index, section) {

                var $section = $(section);

                $section.addClass('order-' + $order);

                if ($section.prev().hasClass('testimonials-grp')) {
                    $section.addClass('white-overlay');
                    count++;
                }

                // if(count % 2 == 1 && !$section.find('.section-full__info').length) {
                //     $section.addClass('white-overlay');
                // }

                if ($order % 2 === 0) {
                    $section.addClass('white-overlay');
                }

                count++;

                let $text_container;
                let text_height;
                let $view_more_button;

                if ($section.hasClass('section-full')) {

                    $text_container = $section.find('.section-full__info');

                    text_height = $text_container.height();

                    if (text_height > 600) {

                        $text_container.addClass('large-text-section');

                        $text_container.parent().parent().css({
                            'z-index': 1
                        });

                        $text_container.append('<button class="services__more services__more_step">View More <i class="icon-chevron-down"></i></button>');

                        $view_more_button = $section.find('button.services__more');

                        bindViewMoreFullSectionClick($text_container, $view_more_button);

                    }

                } else if ($section.hasClass('section-half')) {

                    var $image = $section.find('.row.half>div.section-half__img img');
                    $text_container = $section.find('.row.half>div:not(.section-half__img)');

                    var image_height = $image.height();
                    text_height = $text_container.height(); //

                    // Text is too large. begin adding classes and the view more button
                    if (text_height > image_height + 300) {

                        if ($(window).width() < 600 && $section.hasClass('fix_view')) {

                        } else {
                            var $large_text_row = $section.find('.section-half__container>div');

                            $large_text_row.addClass('large-text-section');

                            $large_text_row.append('<button class="services__more services__more_step">View More <i class="icon-chevron-down"></i></button>');

                            $view_more_button = $section.find('button.services__more');

                            bindViewMoreHalfSectionClick($large_text_row, $view_more_button);
                        }
                    }
                }

                $order++;

            });

        }

        bindViewMoreSections();


        $('.services .services__more').on('click', function () {

            if ($(this).hasClass("services__more_step")) return;

            var text;
            if ($(this).hasClass('active')) {
                text = $(this).html().replace("Close", "View All");
            } else {
                text = $(this).html().replace("View All", "Close");
            }
            $(this).parent().find('.services__list').toggleClass("closed");
            $(this).toggleClass('active');
            $(this).html(text);
            $(this).parents().eq(2).toggleClass('active');
        });

        $('.view-past-tutorial').on('click', function (e) {
            e.preventDefault();
            var text;
            if ($(this).hasClass('active')) {
                text = $(this).html().replace("Hide Past Tutorial", "View Past Tutorial");
            } else {
                text = $(this).html().replace("View Past Tutorial", "Hide Past Tutorial");
            }
            $(this).toggleClass('active');
            $(this).html(text);
            $('.more-info').slideToggle(900);

            $('html, body').animate({
                scrollTop: $('.more-info').offset().top - 75
            }, 500);
        });

        $(".achievements__grp .services__more").on("click", function () {
            $(this).parent().find(".achievements__list").toggleClass("active");

            if ($(this).hasClass('active')) {
                text = $(this).html().replace("Show less", "View 37 More Awards");
            } else {
                text = $(this).html().replace("View 37 More Awards", "Show less");
            }

            $(this).html(text);

            $(this).toggleClass("active");



        });
        $(".insides__nav li").on("click", function () {

          $(".insides__nav li").removeClass("active");
          $(this).addClass("active");

          if ($(this).data().name) {
              if ($(this).data().name == 'All offices') {
                  initMap($(this).data(), true);
              } else {
                  initMap($(this).data(), false);
              }
          }
          let dataTitle = $(this).text().trim();
          let dataExcept = $(this).data().except;
          let dataLink = $(this).data().permalink;

          dataExcept = unescape(dataExcept);


          $(".insides__data.active h3").text(dataTitle);
          $(".insides__data.active p#insides-except").text(dataExcept);
          $(".insides__data.active p#insides-permalink a").attr("href", dataLink);
      });

        $(".insides__nav ul").mCustomScrollbar();
        $(".insides__data > div").mCustomScrollbar({
            axis: 'y',
            mouseWheel: {
                enable: true,
                scrollAmount: 1000
            }
        });

        $(".insides__switch").on("click", function () {
            $(".insides__nav").toggleClass("active");
        });



        setTimeout(function () {
            $(".scroll-vertical").mCustomScrollbar();
        }, 30);

        // End of inside tabs

        $(".truth__factor_holder").mCustomScrollbar({
            axis: "x" // horizontal scrollbar
        });

        //

        // mobile nav

        handleMobileNav();

        function handleMobileNav() {
            var canMove = false;
            var isFirst = true;

            if (isFirst) {
                document.ontouchmove = function (e) {
                    // e.preventDefault();
                };
            }

            // console.log(canMove);

            $(".main-nav__switch").on("click", function () {

                $(this).parents().eq(2).find(".main-nav__open").toggleClass("active");
                $(this).parent().find(".main-nav__selected").removeClass("active level-one level-two colored");

                if ($(this).parents().eq(1).hasClass("main-nav__open")) {
                    $(this).parents().eq(1).find(".active").removeClass("active");
                }


                $("body").toggleClass("open");

                if (canMove) {
                    // Enable scrolling.
                    document.ontouchmove = function (e) {
                        return true;
                    };
                } else {
                    // Disable scrolling.
                    document.ontouchmove = function (e) {
                        // e.preventDefault();
                    };
                }

                canMove = !canMove;

            });

            $(".main-nav__open").delegate(".main-nav__item_down", "click", function (e) {
                e.stopPropagation();

                var html = $(this).find("span").html();
                var isColored = $(this).hasClass("main-nav__item_colored");

                $(this).addClass("active");
                var selected = $(this).parents().eq(1).find(".main-nav__selected");
                selected.removeClass("colored");
                if (isColored) {
                    selected.addClass("colored");
                }
                selected.addClass("active level-one");
                selected.html(html);

            });

            $(".main-nav__open").delegate(".main-nav__level-one > li", "click", function (e) {
                e.stopPropagation();

                var html = $(this).html();

                var parts = html.split("</i>");
                html = parts[0] + "</i>" + parts[1] + "</i>";

                if (html.includes('class="arrow"')) {
                    html = html.split('<span class="arrow"')[0] + '<i class="icon-chevron-right"></i>';
                }
                var isColored = $(this).hasClass("main-nav__item_colored");
                $(this).addClass("active");
                var selected = $(this).parents().eq(3).find(".main-nav__selected");

                selected.removeClass("colored");
                if (isColored) {
                    selected.addClass("colored");
                }
                selected.removeClass("level-one");
                selected.addClass("active level-two");
                selected.html(html);
            });

            $(".main-nav__selected").on("click", function () {

                // $(this).removeClass("active");
                $(this).parents().eq(1).find(".active").last().removeClass("active");


                if ($(this).hasClass("level-two")) {

                    $(this).removeClass("level-two");
                    var el = $(this).parents().eq(1).find(".active").last();
                    var html = el.find("span").html();
                    var isColored = el.hasClass("main-nav__item_colored");
                    $(this).removeClass("colored");
                    if (isColored) {
                        $(this).addClass("colored");
                    }
                    $(this).addClass("active level-one");
                    $(this).html(html);

                } else {
                    $(this).removeClass("active");
                }

            });

            $(".main-nav__open").delegate(".main-nav__level-two > li", "click", function () {
                var current = $(this).find("ul");
                $(".main-nav__level-two > li ul").not(current).slideUp();
                current.slideToggle();
            });

        }

        populateNav();

        function populateNav() {
            var allItems;
            if ($(".header-c_home")[0]) {
                allItems = $(".header-c_home .main-nav__list > .main-nav__item");
            } else if ($(".page-header")[0]) {
                allItems = $(".page-header .main-nav__list > .main-nav__item");
            }

            allItems.each(function () {
                if (!($(this).hasClass("main-nav__item_mobile")) && !($(this).hasClass("main-nav__item_search"))) {
                    $(".main-nav__open > ul").append($(this).clone(true));
                }
            });
        }

        $(".main-nav__level-one > li").on("mouseenter", function () {

            var width = $(this).find(" > ul").innerWidth();

            if (window.innerWidth <= 1100) {
                width = 0;
            }

            //    $(this).parent().data("width", width);

            var root = document.documentElement;
            root.style.setProperty('--width-el', width + "px");

        });

        setTimeout(function () {
            $(".case-study__btn").parent().find('.case-study__list').slideToggle(0);
            $(".case-study__btn").eq(0).parent().find('.case-study__list').slideToggle(0);
        }, 1);


        $('.case-study__btn').on('click', function () {
            var text;
            if ($(this).hasClass('active')) {
                text = $(this).html().replace("More", "Less");
            } else {
                text = $(this).html().replace("Less", "More");
            }

            $(this).parent().find('.case-study__list').slideToggle();
            $(this).toggleClass('active');
            $(this).html(text);
            $(this).parents().eq(2).toggleClass('active');
            $(this).parent().toggleClass("active");
        });

        // $('.press__articles_mansonry').masonry({
        //     fitWidth: true
        // });


        // $(".d-logos .services__more, .offices__control .services__more").on("click", function () {
        //     var text;
        //     if ($(this).hasClass('active')) {
        //         text = $(this).html().replace("Close", "View All");
        //     } else {
        //         text = $(this).html().replace("View All", "Close");
        //     }
        //     $(this).html(text)
        //     $(this).toggleClass('active');

        //     $(this).parents().eq(1).find(".d-logos__list").toggleClass("active");
        // });

        function toggleList(selector, toChange) {
            $(selector).on("click", function () {
                var text;
                if ($(this).hasClass('active')) {
                    text = $(this).html().replace("Close", "View All");
                } else {
                    text = $(this).html().replace("View All", "Close");
                }
                $(this).html(text);
                $(this).toggleClass('active');

                $(this).parents().eq(1).find(toChange).toggleClass("active");
            });
        }
        toggleList(".d-logos .services__more", ".d-logos__list");
        toggleList(".offices__control .services__more", ".offices__list");

        $(".office__map").on("click", function () {

            var map = $(".insides_map");
            $([document.documentElement, document.body]).animate({
                scrollTop: map.offset().top - 150
            }, 1000);
            var name = $(this).data().name;
            var btns = map.find("li");
            btns.each(function () {
                if ($(this).data().name == name) {
                    $(this).trigger("click");
                    $(".insides__nav ul").mCustomScrollbar("scrollTo", $(this));
                }
            });
        });

        $(".info__input input, .info__input textarea").on("keyup", function () {
            if ($(this).val()) {
                $(this).addClass("filled");
            } else {
                $(this).removeClass("filled");
            }
        });

        function toggleCardPopup(item) {
            if (!item) {
                return;
            }
            item.on("click", function (e) {

                var targetElement = $(this).closest('.leaders__card').find(".popup")[0];

                if (e.target.closest('.popup') && !($(e.target).hasClass("icon-cross"))) {
                    return;
                }

                $(this).closest('.leaders__card').find(".popup").toggleClass("active");

                if ($(e.target).hasClass("icon-cross")) {
                    // bodyScrollLock.enableBodyScroll(targetElement);
                    $("body").removeClass("open");
                } else {
                    // bodyScrollLock.disableBodyScroll(targetElement);
                    $("body").addClass("open");
                }

            });

        }

        function toggleMemberPopup(item) {
            if (!item) {
                return;
            }
            item.on("click", function (e) {

                var targetElement = $(this).closest('li').find(".popup")[0];

                if (e.target.closest('.popup') && !($(e.target).hasClass("icon-cross"))) {
                    return;
                }

                $(this).closest('li').find(".popup").toggleClass("active");

                if ($(e.target).hasClass("icon-cross")) {
                    // bodyScrollLock.enableBodyScroll(targetElement);
                    $("body").removeClass("open");
                } else {
                    // bodyScrollLock.disableBodyScroll(targetElement);
                    $("body").addClass("open");
                }

            });

        }

        $(".popup").mCustomScrollbar();

        // Leaders
        toggleCardPopup($(".leaders__card .cta"));
        toggleCardPopup($(".popup .icon-cross"));
        toggleCardPopup($(".leaders__card h3.heading"));
        toggleCardPopup($(".leaders__card img"));

        // Members
        toggleMemberPopup($(".leaders__list li h3"));
        toggleMemberPopup($(".leaders__list li img"));
        toggleMemberPopup($(".popup .icon-cross"));

    });


    //end main.js

    $(".footer__state--item").on("click", function (e) {
        e.preventDefault();

        $("#listCities").empty();
        $("#footerStateName").html($(this).text());

        $(".footer__data_cities").fadeOut();

        var data = {
            action: 'action_get_cities',
            state_id: $(this).data('state_id'),
            office_type: $(this).data('office_type')
        };

        jQuery.post(ajax.url, data, function (response) {
            $(response).each(function (index, item) {
                $("ul#listCities").append('<li><a href="' + item.link + '">' + item.name + '</a></li>');
            });

            $(".footer__data_offices_type").animate({
                height: "0",
            }, 2000);

            $(".footer__data_cities").fadeIn();
            $([document.documentElement, document.body]).animate({
                scrollTop: $("#footerStateName").offset().top
            }, 2000);
            $(".footer__data_offices_type.footer__lists").addClass('hidden');
        });

    });

    $(".footer__list--locations h3").on("click", function () {
        $(".footer__data_cities").fadeOut();
        $(".footer__data_offices_type").animate({
            height: "100%",
        }, 2000);

        $(".footer__data_offices_type.footer__lists").removeClass('hidden');
    });


})(jQuery); // Fully reference jQuery after this point.


// Free tools plugins
jQuery(function ($) {
    var pluginDir = $("#pluginDir").html();

    function escapeHtml(text) {
        var map = {
            '&': '&amp;',
            '<': '&lt;',
            '>': '&gt;',
            '"': '&quot;',
            "'": '&#039;'
        };
        return text.replace(/[&<>"']/g, function (m) {
            return map[m];
        });
    }
    $("#wac-check-btn").click(function () {
        if (!$(this).hasClass('active')) {
            return !1;
        }
        var wac_nonce_security = $("#wac_nonce_security").val();
        $(".wac-results-sec").slideDown();
        $("#wac-check-btn").removeClass('active');
        $(".wac-value").html('<img src="' + pluginDir + 'imgs/loading.gif" />');
        $("#wac-url").html(escapeHtml($("#wac-input-url").val()));
        $.ajax({
            url: "/free-domain-authority-checker/",
            type: "post",
            data: {
                "url": $("#wac-input-url").val(),
                wac_check_authority: 1,
                "nonce_security": wac_nonce_security
            },
            dataType: "json",
            success: function (resp) {
                $("#wac-da").html(resp.domain_auth);
                $("#wac-pa").html(resp.page_auth);
                $("#wac-mr").html(resp.m_rank);
                $("#wac-le").html(resp.linking_domains);
                $("#wac-ip").html(resp.ip);
                $("#wac-check-btn").addClass('active');
            }
        });
    });
});

function findVideos() {
    var videos = document.querySelectorAll('.services__video');

    for (var i = 0; i < videos.length; i++) {
        setupVideo(videos[i]);
    }
}

function setupVideo(video) {
    var videoContainer = video.querySelector('.youtube-video');
    var id = videoContainer.dataset.youtube;
    var idtwo = false;
    if (videoContainer.hasAttribute('data-ytwo')) {
        idtwo = videoContainer.dataset.ytwo;
    }
    video.addEventListener('click', listener, false);

    function listener() {
        var iframe = createIframe(id, idtwo);
        videoContainer.appendChild(iframe);
        video.removeEventListener('click', listener, false);

        setTimeout(function () {
            video.classList.add('active');
        }, 800);
    }
}

function createIframe(id, idtwo) {
    var iframe = document.createElement('iframe');
    iframe.setAttribute('allowfullscreen', '');
    iframe.setAttribute('allow', 'autoplay');
    iframe.setAttribute('src', generateURL(id, idtwo));

    iframe.classList.add('youtube-autoplay');

    return iframe;
}

function generateURL(id, idtwo) {
    var query = '?rel=0&showinfo=0&autoplay=1';
    if (idtwo) {
        return 'https://www.youtube.com/embed/' + id + query + '&playlist=' + idtwo;
    } else {
        return 'https://www.youtube.com/embed/' + id + query;
    }
}

findVideos();
jQuery(document).ready(function ($) {
    $.fn.isInViewport = function () {
        var elementTop = $(this).offset().top;
        var elementBottom = elementTop + $(this).outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        return elementBottom > viewportTop && elementTop < viewportBottom;
    };

    $(window).on('load', function () {
        $('body').addClass('loaded');
    });

    $(window).on('resize scroll load', function () {
        $('.leaders').each(function () {
            if ($(this).isInViewport()) {
                $(this).addClass('isInViewport');
            } else {
                $(this).removeClass('isInViewport');
            }
        });
    });
});
// var tag = document.createElement("script");

// tag.src = "https://www.youtube.com/iframe_api";
// var firstScriptTag = document.getElementsByTagName("script")[0];
// firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

// var player1;
// var player2;
// var player3;
// var player4;

// function onYouTubeIframeAPIReady() {
//     player1 = new YT.Player("player1", {
//         videoId: "tM2ih84ivAs",
//         playerVars: { rel: 0, showinfo: 0, ecver: 2, modestbranding: 1, enablejsapi: 1, origin: 'https://www.overthetopseo.com', host: 'https://www.youtube.com' },
//         events: {}
//     });

//     player2 = new YT.Player("player2", {
//         videoId: "tM2ih84ivAs",
//         playerVars: { rel: 0, showinfo: 0, ecver: 2, modestbranding: 1, enablejsapi: 1, origin: 'https://www.overthetopseo.com', host: 'https://www.youtube.com' },
//         events: {}
//     });

//     player3 = new YT.Player("player3", {
//         videoId: "tM2ih84ivAs",
//         playerVars: { rel: 0, showinfo: 0, ecver: 2, modestbranding: 1, enablejsapi: 1, origin: 'https://www.overthetopseo.com', host: 'https://www.youtube.com' },
//         events: {}
//     });

//     player4 = new YT.Player("player4", {
//         videoId: "tM2ih84ivAs",
//         playerVars: { rel: 0, showinfo: 0, ecver: 2, modestbranding: 1, enablejsapi: 1, origin: 'https://www.overthetopseo.com', host: 'https://www.youtube.com' },
//         events: {}
//     });
// }

// var isFirst1 = true;
// jQuery(".services__video-wrapper-1").on("click", function() {
//     if (isFirst1) {
//         player1.playVideo();
//         var self = jQuery(this);
//         setTimeout(function() {
//             self.parent().addClass("active");
//         }, 800);
//         isFirst1 = false;
//     }
// });

// var isFirst2 = true;
// jQuery(".services__video-wrapper-2").on("click", function() {
//     if (isFirst2) {
//         player2.playVideo();
//         var self = jQuery(this);
//         setTimeout(function() {
//             self.parent().addClass("active");
//         }, 800);
//         isFirst2 = false;
//     }
// });

// var isFirst3 = true;
// jQuery(".services__video-wrapper-3").on("click", function() {
//     if (isFirst3) {
//         player3.playVideo();
//         var self = jQuery(this);
//         setTimeout(function() {
//             self.parent().addClass("active");
//         }, 800);
//         isFirst3 = false;
//     }
// });

// var isFirst4 = true;
// jQuery(".services__video-wrapper-4").on("click", function() {
//     if (isFirst4) {
//         player4.playVideo();
//         var self = jQuery(this);
//         setTimeout(function() {
//             self.parent().addClass("active");
//         }, 800);
//         isFirst4 = false;
//     }
// });
jQuery.event.special.touchstart = {
    setup: function (_, ns, handle) {
        if (ns.includes("noPreventDefault")) {
            this.addEventListener("touchstart", handle, { passive: false });
        } else {
            this.addEventListener("touchstart", handle, { passive: true });
        }
    }
};
